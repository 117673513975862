.body {
  margin: 0;
}
.frame {
  display: flex;
  align-items: space-between;
  /* height: 100vh; */
}
.left {
  width: 70%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
canvas {
  width: 100%;
  height: 100%;
}
pre {
  padding: 1em;
}
/* .slider {
  width: 10px;
  background: gray;
  border-left: solid 2px black;
} */
#app .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

#app,
#app section:first-child {
  height: 100%;
}
.ant-layout-sider-children,
.ant-layout-sider-trigger {
  border-right: solid 1px lightgray;
}
.ant-layout-content {
  overflow: scroll;
}
#canvas .right .ant-list-item {
  padding-left: 12px;
  padding-right: 12px;
}
.canvas-container {
  height: 0 !important;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}
#tiling,
.cesium-viewer-bottom {
  display: none;
}
.cesium-infoBox-title{
  box-sizing:content-box!important;
}
