@import "~react-grid-layout/css/styles";
@import "~react-resizable/css/styles";
@import "~simple-keyboard/build/css/index";
@import "~react-notifications/lib/notifications.css";

//-- /** ------------------------------------------------- set theme

.ant-tabs-nav .ant-tabs-tab {
  margin: 0;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  font-weight: 500;
}

#device_form a {
  margin: 0 12px 0 22px;
  padding: 0 5px 0 5px;
  width: auto;
}

.ant-input-number {
  border-radius: 11px;
}

#dashboard_panel input,
button,
select,
optgroup,
textarea {
  color: initial;
}

.ant-upload-list-item-name {
  padding-right: 12px;
}

.ant-table-row-expand-icon,
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  background: none;
  color: inherit;
}

.iframe_ai {
  border: 1px solid #e8e8e8;
  border-radius: 11px;
}

.ant-popover {
  font-size: 16px;
}

.ant-popover-inner {
  border-radius: 20px;
}

.ant-popover-title {
  padding: 12px;
}

.ant-popover-inner-content {
  padding: 12px;
}

.ant-table-small.ant-table-bordered .ant-table-content {
  border-right: none;
}

.ant-table-small > .ant-table-content > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-body > table,
.ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-header
  > table,
.ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-header
  > table,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-header
  > table,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table {
  border: 0 !important;
}

.ant-pagination-options-size-changer.ant-select {
  margin-right: 0px;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 11px 0 0 11px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 11px 11px 0;
}

.ant-pagination-item {
  border-radius: 11px;
}

.ant-table-bordered.ant-table-fixed-header
  .ant-table-header
  + .ant-table-body
  > table,
.ant-table-bordered.ant-table-fixed-header .ant-table-body-inner > table {
  border-top: 0 !important;
}

.ant-btn-danger-disabled,
.ant-btn-danger.disabled,
.ant-btn-danger[disabled],
.ant-btn-danger-disabled:hover,
.ant-btn-danger.disabled:hover,
.ant-btn-danger[disabled]:hover,
.ant-btn-danger-disabled:focus,
.ant-btn-danger.disabled:focus,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger-disabled:active,
.ant-btn-danger.disabled:active,
.ant-btn-danger[disabled]:active,
.ant-btn-danger-disabled.active,
.ant-btn-danger.disabled.active,
.ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}

.ant-btn-primary-disabled,
.ant-btn-primary.disabled,
.ant-btn-primary[disabled],
.ant-btn-primary-disabled:hover,
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled]:hover,
.ant-btn-primary-disabled:focus,
.ant-btn-primary.disabled:focus,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary-disabled:active,
.ant-btn-primary.disabled:active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary-disabled.active,
.ant-btn-primary.disabled.active,
.ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}

.ant-btn-disabled,
.ant-btn.disabled,
.ant-btn[disabled],
.ant-btn-disabled:hover,
.ant-btn.disabled:hover,
.ant-btn[disabled]:hover,
.ant-btn-disabled:focus,
.ant-btn.disabled:focus,
.ant-btn[disabled]:focus,
.ant-btn-disabled:active,
.ant-btn.disabled:active,
.ant-btn[disabled]:active,
.ant-btn-disabled.active,
.ant-btn.disabled.active,
.ant-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}

#card-grid .ant-card-head,
#card_center_page .ant-card-head,
#excel_template2 .ant-card-head {
  border-bottom: none;
  min-height: 0;
  margin-bottom: 0;
}

#card-grid .ant-card-head-title,
#card_center_page .ant-card-head-title,
#excel_template2 .ant-card-head-title {
  padding-bottom: 0;
}

.ant-card-head {
  padding: 0 16px;
}

.ant-list-bordered.ant-list-sm .ant-list-item {
  padding-right: 0;
  padding-left: 0;
}

#pop_db .ant-list-split .ant-list-item:first-child,
#report-list .ant-list-split .ant-list-item:first-child {
  padding-top: 0;
}

#pop_db .ant-list {
  background: white;
}

#pop_db .ant-list-item-meta-title {
  color: black;
}

.ant-list-split .ant-list-item:last-child {
  padding-bottom: 0;
  border-bottom: none !important;
}

.ant-list-bordered {
  border: unset;
}

.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 0 0 8px 0;
}

.ant-list-item-meta-title {
  margin-bottom: 0;
}

.ant-list-item-action {
  margin-left: 16px;
}

.ant-list-item-action > li {
  padding: 0;
}

.ant-input,
.ant-select-selection,
.ant-btn {
  border-radius: 11px;
}

.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.ant-input-search > input,
.search .ant-select-selection {
  border: unset;
}

.ant-drawer-header {
  border-radius: 0;
  height: 56px;
  border-bottom: unset;
}

.ant-card {
  border-radius: 24px;
}

.ant-card-body {
  padding: 16px;
}

#log_template .ant-card-body,
#excel_template .ant-card-body {
  padding: 12px 16px;
}

#ai_page .ant-card-body,
#log_graph .ant-card-body,
#excel_template2 .ant-card-body {
  padding: 12px 16px 16px;
}

.ant-card-bordered {
  border: unset;
}

.ant-collapse-header {
  border-radius: 4px 4px 0 0 !important;
}

.ant-collapse-content {
  border-radius: 0 0 4px 4px !important;
}

.ant-collapse {
  border: unset;
}

.ant-collapse > .ant-collapse-item {
  border-bottom: unset;
}

.ant-collapse-content {
  border-top: unset;
}

.ant-layout-header {
  height: 56px;
  line-height: 56px;
  z-index: 1;
  box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.08);
}

tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
  box-shadow: 0 3px 33px 0 rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 0 3px 33px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 3px 33px 0 rgba(0, 0, 0, 0.14);
}

.ant-menu-horizontal {
  line-height: 53px;
}

//-- **/ ------------------------------------------------- set theme

.ant-btn-link {
  color: #1890ff;
}

.ant-badge-status-success::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #28a745;
  border-radius: 50%;
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: "";
}

.ant-badge-status-warning::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #ffc107;
  border-radius: 50%;
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: "";
}

.ant-badge-status-error::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #dc3545;
  border-radius: 50%;
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: "";
}

.ant-badge-status-default::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #6c757d;
  border-radius: 50%;
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: "";
}

.wordBreak {
  word-break: break-all;
}

.whiteSpace {
  white-space: normal;
}

.ant-select-dropdown {
  z-index: 1061;
}

// .sidebar {
//   height: 100%; /* 100% Full-height */
//   width: 0; /* 0 width - change this with JavaScript */
//   position: fixed; /* Stay in place */
//   z-index: 1; /* Stay on top */
//   left: 0;
//   overflow-x: hidden; /* Disable horizontal scroll */
//   transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
// }

// .sidebar-right {
//   height: 100%; /* 100% Full-height */
//   width: 0; /* 0 width - change this with JavaScript */
//   position: fixed; /* Stay in place */
//   z-index: 1; /* Stay on top */
//   right: 0;
//   overflow-x: hidden; /* Disable horizontal scroll */
//   transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
// }

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
// #proj-content {
//   transition: margin 0.5s; /* If you want a transition effect */
// }

#card-grid > .ant-card-body {
  padding: 6px !important;
}

#connect_tag .ant-empty-normal {
  margin: 0;
}

#connect_tag .ant-tree li .ant-tree-node-content-wrapper {
  white-space: normal;
  height: auto;
}

#connect_tag {
  direction: rtl;
}

#connect_tag .ant-card-body {
  direction: ltr;
}

.Spin_in_container {
  text-align: center;
  padding: 40px 50px;
}

#logReOrder .ant-table-body,
#rule_table .ant-table-body {
  margin: 0px;
}

.drag-handle {
  cursor: move !important;
}

.dragging-container tbody {
  xposition: relative;
  xoverflow: hidden;
}

.dragging-container tbody tr {
  background: #fff !important;
  xtransition: none !important;
}

.dragging-container tbody tr td {
  position: relative;
}

.dragging-container .drag-target-line {
  bottom: -1px;
  left: -554px;
  width: 652px;
  xleft: 0;
  xwidth: 100%;
  height: 0;
  display: block;
  position: absolute;
  border-bottom: dashed 2px red;
  z-index: 2;
}

.dragging-container .drag-target-line.drag-target-top {
  bottom: auto;
  top: -1px;
}

.dragging-container .ant-table-tbody > tr.ant-table-row-hover > td,
.dragging-container
  .ant-table-tbody
  > tr
  > td
  .dragging-container
  .ant-table-tbody
  > tr:hover
  > td {
  background: transparent;
}

@media (max-width: 575px) {
  .ant-advanced-search-form .ant-form-item {
    display: unset !important;
  }
}

#log_template .ant-select-selection,
#device_form .ant-select-selection,
#template_form .ant-select-selection,
.select_group .ant-select-selection,
.select_group .ant-calendar-picker-input.ant-input {
  border-radius: 11px 0px 0px 11px;
}

.select_group_no_radius .ant-select-selection {
  border-radius: 0;
}

.ant-advanced-search-form {
  padding: 8px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none;
}

.ant-layout-sider-children {
  border-right: none;
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  font-size: 20px;
}

.ant-menu-inline-collapsed > .ant-menu-item .anticon {
  font-size: 20px;
}

.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 30px !important;
}

.ant-layout-sider-light .ant-layout-sider-trigger {
  background: #fa541c;
  color: #fff;
}

.ant-layout-content {
  overflow: auto;
}

.ant-layout-sider {
  overflow: hidden auto;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 4px;
}

.ant-typography {
  word-break: break-all;
}

.logo {
  width: 176px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}

.font-Menu {
  font-size: 16px;
  font-weight: bold;
}

.font-Menu-sub {
  font-size: 15px;
  font-weight: 500;
}

.icon-Menu-sub {
  font-size: 18px !important;
}

.card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

// .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
//   border-color: #fff;
//   background: #fff;
// }

.ant-tabs-nav-wrap {
  float: right;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  margin-right: 15px;
}

// .ant-tabs-tab-active {
//   border: 1px solid lightgray !important;
//   border-bottom: 0 !important;
//   border-radius: 0 !important;
// }

#dom-drawing {
  height: 100%;
}

// #proj-content .ant-card-body {
//   height: 100%;
// }

.ant-tabs-tabpane-active {
  overflow: auto;
}

.react-grid-item {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
}

.ant-card-wider-padding .ant-card-body {
  padding: 24px 24px;
}

.ant-table-pagination.ant-pagination {
  margin-bottom: 0px;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// ------------------------------- pure-table

.pure-table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #cbcbcb;
}

.pure-table thead {
  background-color: #e0e0e0;
  color: #000;
  text-align: left;
  vertical-align: bottom;
  cursor: move;
}

.pure-table td:first-child,
.pure-table th:first-child {
  border-left-width: 0;
}

.pure-table td,
.pure-table th {
  border-left: 1px solid #cbcbcb;
  border-width: 0 0 0 1px;
  font-size: inherit;
  margin: 0;
  overflow: visible;
  // padding: 0.5em 1em;
  padding: 12px 8px;
}

.pure-table-striped tr:nth-child(2n-1) td {
  background-color: #f2f2f2;
}

.pure-table td {
  background-color: transparent;
}

.info {
  & span:first-child {
    &::after {
      content: ",";
      margin: 0 4px;
    }
  }
  & span:last-child {
    &::before {
      content: "–";
      margin: 0 4px;
    }
  }
}

.react_time_range__time_range_container {
  padding: 30px !important;
  width: 100% !important;
}
